import { PrimeNGModule } from './primeng.module'
import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PhoneFormatPipe } from '../pipes/phone.pipe'
import { DateFormatPipe } from '../pipes/date.pipe'

@NgModule({
  declarations:
    [
      PhoneFormatPipe,
      DateFormatPipe
    ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNGModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimeNGModule,
    PhoneFormatPipe,
    DateFormatPipe
  ],
  providers: [
    DatePipe
  ],
})
export class AppBaseModule { }
